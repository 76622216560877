
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { Item as IItem } from '@/types/Item';
import ItemImage from '@/components/Item/ItemImage.vue';
import { getItemColor } from '@/helpers/getItemColor';
import CaseImage from '@/components/Case/CaseImage.vue';

@Component({ components: { ItemImage, CaseImage } })
export default class Item extends Vue {
  @Prop() readonly itemData: IItem;
  @Prop(Boolean) readonly hideTitle?: Boolean;
  @Prop(Boolean) readonly enableShadow?: Boolean;
  @Prop(Boolean) readonly changeWidth?: Boolean;
  @Prop(Boolean) readonly priceModeText?: Boolean;
  @Prop(Boolean) readonly boxShadow?: Boolean;
  @Prop({ type: String, required: false, default: 'normal' })
  readonly priceMode?: 'hidden' | 'hover' | 'normal';
  @Prop({ type: String, required: false, default: 'disabled' })
  readonly hoverMode?: 'disabled' | 'normal';
  @Prop({ type: String, required: false, default: '' })
  readonly customShadow?: string;
  isHovered: boolean = false;
  isHoverAllowed: boolean = false;

  getColor(color: string) {
    return getItemColor(color);
  }

  get shadowStyle() {
    return (
      (this.customShadow
        ? this.customShadow
        : this.enableShadow
          ? 'inset 0px -1px 15px 1px'
          : 'inset 0px -15px 15px 1px') +
      ' #' +
      this.getColor(this.itemData?.color)
    );
  }

  @Watch('isHovered')
  handleHoveredChange() {
    if (this.isHovered) {
      setTimeout(() => {
        this.isHoverAllowed = true;
      }, 40);
    } else {
      this.isHoverAllowed = false;
    }
    this.$emit('hovered', this.isHovered);
  }
}
