import { Item } from '@/types/Item';

export const getItemImage = (
    item: Pick<Item, 'type' | 'image'>,
    size: { width: number; height: number } = {width: 200, height: 132}
) => {
    return item.type !== 'item'
        ? item.image
        : `https://steamcommunity-a.akamaihd.net/economy/image/${item.image}/${
            size.width
        }fx${size.height}f`;
};
